import { Inject, Injectable, Injector } from "@angular/core";
import { ToastrService } from 'ngx-toastr';

export class ToastStatus {
  public static SUCCESS = 'success';
  public static DANGER = 'danger';
  public static WARNING = 'warning';
  public static PRIMARY = 'primary';
  public static DEFAULT = 'default';
  public static INFO = 'info';
};

/**
 * Service to show tostr message to the user
 */
@Injectable({
  providedIn: 'root'
})
export class ToasterService {
  /**
   * Constructor
   * @param toastrService 
   */
  constructor(@Inject(Injector) private readonly injector: Injector) {
  }

  private get toastrService() {
    return this.injector.get(ToastrService);
  }

  /**
   * To show toast to the User
   * @param status status of the toast
   * @param title title of the toaster
   * @param message body of the toaster
   */
  showToast(status, title, message) {
    this.toastrService.show(message, title, {}, status);
  }

  /**
   * To show the sucess toast to the user
   * @param message 
   * @param title 
   */
  showSuccessToast(message, title = "Success") {
    this.toastrService.success(message, title);
  }

  /**
   * To sho the error toast to the user
   * @param message 
   * @param title 
   */
  showErrorToast(message, title = "Error") {
    this.toastrService.error(message, title);
  }
}